<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">我的足迹</div>
      <div
        class="list_box"
        v-loading="loading"
        element-loading-text="正在加载数据"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0,.1)"
      >
        <div v-for="(item, index) in printList" :key="index">
          <el-divider content-position="left">
            <div class="timerDate">{{ item.date }}</div>
          </el-divider>
          <div :class="['list_box_child', item.footprintList.length < 6 ? 'list_box_childActive' : '']">
            <div @click="getDetails(res)" v-for="(res, k) in item.footprintList" :key="k" :class="['list_child']">
              <svg-icon v-if="res.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
              <img :src="res.imgPath" alt="" />
              <div class="styleClass">{{ res.styleNumber }}</div>
              <div class="titleClass">{{ res.productName }}</div>
              <div class="timerClass">{{ res.updateTime }}</div>
              <div class="outStock collectionStock" v-if="res.status == '2'">
                已下架
              </div>
              <div class="outStock collectionStock" v-if="res.status == '1'">
                售完
              </div>
            </div>
          </div>
        </div>
        <div v-if="printList.length <= 0" style="text-align:center;margin:200px 0;width: 100%;">
          <img src="@/Img/noData.png" alt />
        </div>
      </div>
      <div class="boe_fenye">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="obj.current"
          :page-sizes="[12, 24, 36, 48, 60, 120]"
          :page-size="obj.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
import { getFootprintList } from '@/api/footprint';
export default {
  name: 'Footprint',
  data() {
    return {
      obj: {
        current: 1,
        size: 12,
      },
      loading: true,
      total: 0,
      printList: [],
    };
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {
    this.getFootprintList();
  },
  methods: {
    // 跳转商品详情页
    getDetails(data) {
      if (data.status == '2') {
        this.$message({
          message: '该商品已下架！',
          type: 'warning',
          duration: 1500,
        });
      } else {
        if (data.classify == '2') {
          this.$router.push({
            path: '/order',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              codeType: data.columnCode,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '1') {
          this.$router.push({
            path: '/plaingold',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '3') {
          this.$router.push({
            path: '/watch',
            query: {
              id: data.productId,
              generaName: data.title,
              productName: '手表现货',
              serialNumber: data.serialNumber,
              styleNumber: data.styleNumber,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        } else if (data.classify == '4') {
          this.$router.push({
            path: '/goldsetting',
            query: {
              id: data.productId,
              generaName: data.productName,
              styleNumber: data.styleNumber,
              classify: data.classify,
              groupFlag: data.groupFlag,
            },
          });
        }
      }
    },
    // 分页组件切换页数
    handleSizeChange(val) {
      this.obj.size = val;
      this.getFootprintList();
    },
    // 分页组件切换
    handleCurrentChange(val) {
      this.obj.current = val;
      this.getFootprintList();
    },
    // 获取足迹列表
    getFootprintList() {
      this.loading = true;
      getFootprintList(this.obj).then((res) => {
        this.loading = false;
        this.printList = [];
        res.data.records.forEach((element) => {
          this.printList.push(element);
        });
        this.total = res.data.total;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 600px;
}

.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0;
}

.boe_fenye {
  text-align: center;
  margin-top: 20px;
}

::v-deep .el-divider--horizontal {
  margin-bottom: 24px !important;
}

.timerDate {
  font-weight: bold;
  font-size: 18px;
  color: #000;

  span {
    font-size: 14px;
  }
}

.list_box_child {
  display: grid;
  grid-row-gap: 20px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

  .pre-sale-label {
    position: absolute;
    width: 88px;
    height: 28px;
    right: 0;
    top: 0;
  }
}

.list_box_childActive {
  display: flex;
  flex-wrap: wrap;

  .list_child {
    margin-left: 10px;
    position: relative;
  }

  .list_child:first-child {
    margin-left: 5px;
  }
}

.list_child {
  width: 240px;
  border: 1px solid #ec9368;
  // margin: 0 0 10px 8px;
  box-sizing: border-box;
  position: relative;

  img {
    width: 100%;
    height: 238px;
  }

  .styleClass {
    font-size: 16px;
    color: #ec9368;
    margin: 5px;
  }

  .titleClass {
    font-size: 16px;
    color: #000;
    margin-left: 5px;
    margin-bottom: 5px;
  }

  .timerClass {
    font-size: 14px;
    color: #999;
    margin-left: 8px;
    margin-bottom: 5px;
  }

  .collectionStock {
    width: 103px;
    height: 103px;
  }
}
</style>
