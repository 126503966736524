import request from "@/utils/request";

// 获取足迹页列表数据
export function getFootprintList(data) {
  return request({
    url: `/exhibition/footprint/page?size=${data.size}&current=${data.current}`,
    method: "get",
    params: {},
  });
}
